import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import LayoutPosts from "../components/layout-posts"
import * as TravelPostStyles from "./travel-post.module.css"

export default function TravelBlogPost({ data }) {
  const post = data.mdx
  return (
    <LayoutPosts>
      <div className={TravelPostStyles.layoutMargin}>
        <br />
        <h1 className={TravelPostStyles.postTitle}>{post.frontmatter.title}</h1>
        <div className={TravelPostStyles.postDate}>
          <small>{post.frontmatter.date}</small>
        </div>
        <br />
        <div className={TravelPostStyles.postBody}>
          <MDXProvider
            components={{
              a: props => <a {...props} className={TravelPostStyles.postA} />
            }}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </LayoutPosts>
  )
}

export const query = graphql`
      query TravelPageQuery($slug: String!) {
        mdx(fields: {slug: {eq: $slug } }) {
        body
      frontmatter {
        title
        date(fromNow: true)
      featuredImage {
        childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
      `
